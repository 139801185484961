// @ts-ignore
export const underTest = !!window.Cypress;

export const NOTISTACK_MAX_SNACKS = 3;
export const LAST_SELECTED_BRANCH = 'lastSelectedBranch';

export const ZOHO_SIGN_ORGANIZATION_ID = 655695013;

export const ZOHO_SIGN_EXTENSION_LINK_HOLDERS = {
  SalesProId: '${SalesPro.SalesProId}',
  SalesProName: '${SalesPro.SalesProName}',
  OrganizationId: '${Organization.OrganizationId}',
  Module: '${ModuleName}',
};

export const ZOHO_SIGN_EXTENSION_LINK =
  'https://extensions.zoho.com/plugin/zohosign/handler?action=createZohoSignDocument&module=${ModuleName}&rec_id=${SalesPro.SalesProId}&rec_name=${SalesPro.SalesProName}&crmzgid=${Organization.OrganizationId}';
